@import '../../../../styles/customMediaQueries.css';

.weBeliveSection{
  width:100%;
  margin: auto;
  display: block;
  margin-top: 50px;
  margin-bottom: 50px;

  @media (--viewportMedium) {
    width: 100%;
    display: flex;
       }
 
   @media (--viewportLarge) {
    width: 70%;
    display: flex;
   }
}
.happyImage{
  border-radius: 8px;
  max-width: 100%;
  @media (--viewportMedium) {
    max-width: 400px;
       }
 
   @media (--viewportLarge) {
    width: 70%;
    max-width: 400px;
   }
}
.contentSec{
  text-align: center;
  padding: 0 5%
}
.textCenter{
  text-align: center;
  font-size: .875rem;
  line-height: 1.86;
}

.buttonSearchAll{
  width: 162px;
  min-height: 46px;
  margin: auto;
  margin-top: 25px;
  background-color: #ff5a5f!important;
 }
 .patnersSection{
  width: 70%;
  margin: auto;
  text-align: center;
 }

 .patnersLogo{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

 }
 .patnersLogo img{
  
  height: 50px !important;
  @media (--viewportLarge) {
      height: 100px !important;
 }
}
 .customBold{
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
 }
 .patnersLogoHeading{
  font-weight: 500;
  & b{
    font-weight: 600;
  }
 }