@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 59vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title{
    text-align: center !important;
    font-size:28px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 50px;

    @media (--viewportMedium) {
      margin-bottom: 18px;
      font-size: 68px;
    }
}