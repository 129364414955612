@import '../../../../styles/customMediaQueries.css';

.communitySection{
  width: 100%;
  padding: 10px;
  margin: auto;
  text-align: center;
  @media (--viewportMedium) {
    width: 100%;
      }

  @media (--viewportLarge) {
    width: 75%;
  }
}
.hPrimary{
  color: #3a5795!important;
  letter-spacing: 2.63px !important;
  font-weight:400 ;
}
.landingSubTItle{
  color: #252525;
  font-size: 2rem;
  text-align: center;
  margin:26px 0 26px 0;
  font-weight: 500;
 }

 .communityGrid{
  display: block;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
   display: flex;
   margin-bottom: 0px;
      }

  @media (--viewportLarge) {
    display: flex;
    margin-bottom: 0px;
  }
 }
 .dateDisplay{
  color: #696969;
  font-size: 16px;
 }
 .communityText{
  color: #333!important;
  font-size: 18px;
 }
 .communityCard{
  box-shadow: 0 0 10px 0 rgba(51,51,51,.15)!important;
  background:#FFF ;
  border-radius: 8px;
  width: 100%;

  @media (--viewportMedium) {
    width: 33%;
       }
 
   @media (--viewportLarge) {
    width: 33%;
   }

 }
 .communityCard img {
width: 100%;
 }
 .communitycontents{
  padding: 24px;
  text-align: left;
  min-height: 184px;
 }
 .communityCard p{
  margin: 0;
 }
 .buttonSearchAll{
  width: 162px;
  min-height: 46px;
  margin: auto;
  margin-top: 25px;
  background-color: #ff5a5f!important;
 }

 .buttonSearchAllKeepWatching{
  width: 190px;
  min-height: 46px;
  margin: auto;
  margin-top: 25px;
  background-color: #ff5a5f!important;
 }
.esaExp{
  background: url(./img/videoWLK.png) no-repeat top left;
  height: 400px;
  background-size: cover;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.modaloverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal */
.modal {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  /* max-width: 642px;
  width: 100%;
  height: 444px; */
}

/* Modal Header */
.modalheader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -23px;
  z-index: 9999;
  position: relative;
}

/* Modal Content */
.modalcontent {
  /* Add your custom styles for modal content */
  margin-top: 15px;
}

/* Close Button */
.closebtn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #888;
  /* position: absolute; */
  float: right;
  margin-right: -22px;
  margin-top: -15px;
}
.anchor{
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}
.readMoreArrow{
  color: #3a5795!important;
  font-weight: 800;
  margin-left: .3125rem !important;
}