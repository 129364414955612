@import '../../../../styles/customMediaQueries.css';

@import '../../../../styles/customMediaQueries.css';


.esiansBlock{
  width: 90%;
  margin: auto;
  text-align: center;

  @media (--viewportMedium) {

    width: 70%;
      }

  @media (--viewportLarge) {
    width: 70%;
  }

}
.landingSubTItle{
  color: #252525;
  font-size: 2rem;
  text-align: center;
  margin:56px 0 26px 0;
  font-weight: 500;
 }
 .esianItem{
  grid-gap: 24px;
  display: block;
  gap: 24px;
  grid-template-columns:repeat(3,1fr);
  padding: 0 0 20px 0px;
  width: 100%;

  @media (--viewportMedium) {
    display: grid;
      }

  @media (--viewportLarge) {
    display: grid;
  }

 }
 .description{
  margin-top: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.86;
  color: #696969;
}
 .esianItem img{
width: 100%;
border-radius: 8px;
cursor: pointer;
 }
 .esianItem img:hover{
  opacity: 0.7;
  cursor: default;
 }
 .teamHeading{
  font-size: 22px;
  color: #333 !important;
  margin-bottom: 0;
  font-weight: 600;
 }
 .buttonSearchAll{
  width: 162px;
  min-height: 46px;
  margin: auto;
 }



 .howItWorks{
  background: #fafafa!important;
  width: 100%;
  margin:auto;
  padding: 14px;
  
  @media (--viewportMedium) {
    width: 100%;
      }

  @media (--viewportLarge) {
    width: 100%;
    padding: 0% 10%;
  }

 }

 .howItWorksItem{
  grid-gap: 24px;
  display: block;
  gap: 24px;
  grid-template-columns:repeat(1,1fr);
  padding: 0 0 20px 0px;
  width: 100%;
  justify-content: space-between;

& .itemBlockSection{
box-shadow: 0 5px 15px 0 rgba(51,51,51,.08)!important;
border: 1px solid #eee!important;
width: 100%;
padding: 20px;
background: #FFF;

&:hover{
box-shadow: 0 11px 30px 0 rgba(51,51,51,.15)!important;
}
ol{
  margin: 0;
}
}

  @media (--viewportMedium) {
    display: flex;
      }

  @media (--viewportLarge) {
    display: flex;
  }
 }
 
 .itemContentHiv{
  display: flex;
  align-items: center;
  justify-content: flex-start;
 }
.itemContentHiv img{
  width: 100px !important;
  @media (--viewportLarge) {
    width: 200px !important;
  }
}
 .itemContentHiv li{
  font-size: 14px;
 }

 .headingHowitWorks{
  font-size: 18px;
  color:rgba(0,0,0,.5)!important;
  margin: 0px;
  font-weight: 500;
 }
 .headingHowitWorksLastRow{
  font-size: 18px;
  color:rgba(0,0,0,.5)!important;
  margin: 0px;
  font-weight: 500;
  text-align: left;
 }

 .topbarLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 0px 0;
  text-decoration: inherit;
  position: relative;
}

.topbarLink {
  flex-shrink: 0;

  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    background-color: #3a5795 !important;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 17px;
    margin: 0;
  }
}
.topbarLink{
  border-color: #ff5a5f;
  background-color: #ff5a5f !important;
  font-size: .875rem;
  color: #FFF;
  padding: .8rem;
  border-radius: 4px;
}
.topbarLink:last-child{
  background-color: #3a5795 !important;
  width: 300px;

  &:hover {
    background-color: #ff5a5f !important;
  }
}
.topbarLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 0px 0;
  text-decoration: inherit;
  position: relative;
  width: 135px;
}
.searchUserPage{
    /* width: 162px; */
    min-height: 46px;
    margin: auto;
    margin-top: 25px;
    background-color: #3a5795 !important;
    display: flex;
    width: fit-content;
    align-items: center;
    border-radius: 6px;
    padding: 10px 15px;
&:hover{
  background-color: #ff5a5f !important;
}
  & a{
    color: #FFF;
    text-decoration: none;
  }
}
.iframeBecomeaHost{
  width: 90%;
  height: 300px;

  @media (--viewportMedium) {
    width: 640px;
    height: 360px;
    align-items: center;
}
}
.howItWorksVideo{
  text-align: center;
}