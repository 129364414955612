@import '../../../../styles/customMediaQueries.css';


.blockService{
  width: 90%;
  margin: auto;
}
.landingSubTItle{
  color: #252525;
  font-size: 2rem;
  text-align: center;
  margin: 56px 0;
font-weight: 500;
  & b{
    font-weight: 700;
  }
 }

 .servicesSection{
 
  align-items: center;
  color: var(--colorWhite);
  overflow: hidden;
  gap: 30px;

  @media (--viewportMedium) {
    display: flex;
      }

  @media (--viewportLarge) {
    display: flex;
  }
}

 .hostFamily{
   width: 100%;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 24px;

    @media (--viewportMedium) {
      display: flex;
      width: 60%;
      margin-bottom: 0px;
        }
  
    @media (--viewportLarge) {
      display: flex;
      width: 60%;
      margin-bottom: 0px;
    }

 }
 .headingScaled{
  display: none;
 }
 .hostFamily:hover .headingScaled{
  background: #3333338f;
  color: #FFF;
  text-align: right;
  position: absolute;
  z-index: 99;
  display: flex;
  /* justify-content: space-between; */
  padding: 27px 27px;
  margin: 0;
  font-size: 2.5rem;
  transition: 0.s;
 }

 .hostFamilyContent{
    background: url(./img/welcome_image.jpg) no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    transform: scale(1);
    transition: 1s ease-in-out;
    min-height:450px;
    cursor: pointer;
    &:hover{
      transform: scale(1.2);
    }
 }
 .admission{
  width:100% ;
  border-radius: 8px;
  overflow: hidden;

  @media (--viewportMedium) {
    display: flex;
    width: 40%;
    margin-bottom: 0px;
      }

  @media (--viewportLarge) {
    display: flex;
    width: 40%;
    margin-bottom: 0px;
  }
 }
 .admissionScaled{
  display: none;
 }
 .admission:hover .headingScaled{
  background: #3333338f;
  color: #FFF;
  text-align: right;
  position: absolute;
  z-index: 99;
  display: flex;
  /* justify-content: space-between; */
  padding: 27px 27px;
  margin: 0;
  font-size: 2.5rem;
  transition: 0.2s;
 }
 .admissionContent{
  background: url(./img/admission.jpg) no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  transform: scale(1);
  transition: 1s ease-in-out;
  min-height:450px;
  cursor: pointer;
  &:hover{
    transform: scale(1.2);
  }
 }



 .servicesSectionsecond{
  display: block;
  align-items: center;
  color: var(--colorWhite);
  overflow: hidden;
  gap: 30px;
  margin-top: 30px;

  @media (--viewportMedium) {
    display: flex;
      }

  @media (--viewportLarge) {
    display: flex;
  }
}


 .handEsa{
    width: 100%;
    overflow: hidden;
    border-radius: 8px;

    @media (--viewportMedium) {
      display: flex;
      width: 50%;
      margin-bottom: 0px;
        }
  
    @media (--viewportLarge) {
      display: flex;
      width: 50%;
      margin-bottom: 0px;
    }

 }
 .headingScaledSecond{
  display: none;
 }
 .handEsa:hover .headingScaledSecond{
  background: #3333338f;
  color: #FFF;
  text-align: right;
  position: absolute;
  z-index: 99;
  display: flex;
  /* justify-content: space-between; */
  padding: 27px 27px;
  margin: 0;
  font-size: 2.5rem;
  transition: 0.5s;
  font-weight: 500;
 }

 .headingScaledSecondContent{
    background: url(./img/esa_wlh.png) no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    transform: scale(1);
    transition: 1s ease-in-out;
    min-height: 300px;
    cursor: pointer;
    &:hover{
      transform: scale(1.2);
    }
 }
 .welcoming{
  width:100% ;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 24px;
  @media (--viewportMedium) {
    display: flex;
    width: 50%;
    margin-top: 0px;
      }

  @media (--viewportLarge) {
    display: flex;
    width: 50%;
    margin-top: 0px;
  }
 }
 .welcomingScaled{
  display: none;
 }
 .welcoming:hover .welcomingScaled{
  background: #3333338f;
  color: #FFF;
  text-align: right;
  position: absolute;
  z-index: 99;
  display: flex;
  /* justify-content: space-between; */
  padding: 27px 27px;
  margin: 0;
  font-size: 2.5rem;
  transition: 0.2s;
 }
 .welcomingContent{
  background: url(./img/welcome_wlh.jpg) no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  transform: scale(1);
  transition: 1s ease-in-out;
  min-height: 300px;
  cursor: pointer;
  &:hover{
    transform: scale(1.2);
  }
 }
